<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBL0000215" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-appr-btn 
              ref="appr-btn"
              name="work-permit-appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="approvalPreWorkCheck"
              @callbackApprAction="approvalPreWorkCheckCallback"
              @requestAfterAction="getDetail('save')"
            />
            <c-btn
              v-show="editable && completeEditable && !disabled" 
              :isSubmit="isComplete"
              :url="completeUrl"
              :param="preWorkCheck"
              mappingType="PUT"
              label="완료"
              icon="check"
              @beforeAction="completePreWorkCheck"
              @btnCallback="completePreWorkCheckCallback" />
            <c-btn
              v-show="editable && !disabled" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="preWorkCheck"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="savePreWorkCheck"
              @btnCallback="savePreWorkCheckCallback" />
            <!-- 삭제 -->
            <c-btn
              v-show="editable && !disabled && isOld"
              label="LBLREMOVE"
              icon="remove"
              @btnClicked="removePreWorkCheck" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <!-- 점검명 -->
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="점검명"
              name="checkName"
              v-model="preWorkCheck.checkName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-dept
              :required="true"
              :disabled="isOld"
              :editable="editable"
              type="edit"
              label="점검주관부서"
              name="deptCd"
              v-model="preWorkCheck.deptCd"
              @datachange="(deptCd, deptName) => changePlantDept('D', deptName)">
            </c-dept>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <!-- 점검구분대상 -->
            <c-select
              :disabled="isOld"
              :editable="editable"
              :comboItems="checkClassItems"
              type="edit"
              itemText="sopPreWorkCheckClassName"
              itemValue="sopPreWorkCheckClassCd"
              label="점검구분대상"
              name="sopPreWorkCheckClassCd"
              v-model="preWorkCheck.sopPreWorkCheckClassCd"
              @datachange="changeInfo"
            />
          </div>
          <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
            <c-datepicker
              :required="true"
              :disabled="isOld"
              :editable="editable"
              default="today"
              type="month"
              label="점검년월"
              name="yearmonth"
              v-model="preWorkCheck.yearmonth"
              @datachange="changeInfo"
            />
          </div>
          <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
            <c-field
              :disabled="disabled"
              :editable="editable"
              :data="preWorkCheck"
              :plantCd="preWorkCheck.plantCd"
              deptValue="deptCd"
              type="dept_user"
              label="관리감독자"
              name="managerId"
              v-model="preWorkCheck.managerId" />
          </div>
          <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
            <!-- 사업장 -->
            <c-plant
              :required="true"
              :disabled="isOld"
              :editable="editable"
              label="LBL0000200"
              name="plantCd"
              v-model="preWorkCheck.plantCd"
              @datachange="() => changePlantDept">
            </c-plant>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.componentKey"
          :popupParam.sync="popupParam"
          :preWorkCheck.sync="preWorkCheck"
          :grid.sync="grid"
          :imprTabInfo.sync="imprTabInfo"
          :isOld="isOld"
          :disabled="disabled"
          :height="contentHeight"
          @save="savePreWorkCheck"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
// import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'preWorkCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          sopPreWorkCheckId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      preWorkCheck: {
        sopPreWorkCheckId: '',  // 작업 전 안전점검 일련번호
        plantCd: null,  // 사업장 코드
        pwcPreWorkCheckStepCd: '',  // 작업 전 안전점검 진행상태
        sopPreWorkCheckClassCd: null,  // 작업 전 안전점검 구분 코드
        apprCycle: '', // 점검결재 주기
        checkName: '',  // 점검명
        saveDeptCd: '',  // 주관부서
        deptCd: '',  // 주관부서
        deptName: '',  // 주관부서
        managerId: '',  // 관리감독자 일련번호
        yearmonth: '',  // 년월
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        monthResults: [],
        imprs: [],
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      checkClassItems: [],
      checkOriginItems: [],
      checkItems: [],
      tab: 'result',
      tabItems: [
        { name: 'result', class: 'tab-bluegrey', icon: 'construction', label: '점검결과', component: () => import(`${'./preWorkCheckTargetResult.vue'}`), },
        { name: 'impr', icon: 'account_tree', label: '개선', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`) },
      ],
      grid: {
        merge: [
          { index: 0, colName: 'sopPreWorkCheckClassCd' },
          { index: 1, colName: 'checkItemName' },
        ],
        columns: [],
        defaultColumns: [
          {
            fix: true,
            name: 'sopPreWorkCheckClassName',
            field: 'sopPreWorkCheckClassName',
            label: '점검구분',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            fix: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목명',
            align: 'left',
            sortable: true,
            style: 'width:150px',
          },
          {
            fix: true,
            name: 'checkItemSubName',
            field: 'checkItemSubName',
            label: '점검세부항목명',
            align: 'left',
            sortable: true,
            style: 'width:300px',
          },
        ],
        height: '500px'
      },
      editable: true,
      detailUrl: '',
      holidayUrl: '',
      listItemUrl: '',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let height = this.contentHeight - 200;
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.sopPreWorkCheckId)
    },
    disabled() {
      // 모든 입력폼이 비활성화 되는 상황
      // - 취소  - 작업완료
      // - 결재중
      return this.isOld 
            // - 취소  - 작업완료
        && (this.preWorkCheck.pwcPreWorkCheckStepCd === 'PWC000005' 
          // 결재중인 경우 비활성화
          || this.preWorkCheck.approvalStatusCd === 'ASC0000001'
          // 점검주관부서가 아닌경우 비활성화
          || this.preWorkCheck.saveDeptCd !== this.$store.getters.user.deptCd
        )
    },
    completeEditable() {
      return this.isOld
        && this.preWorkCheck.pwcPreWorkCheckStepCd === 'PWC000001'
        && this.preWorkCheck.apprCycle === 'D'
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.preWorkCheck.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.preWorkCheck.approvalStatusCd, 
        apprEditable: this.isOld 
          && this.preWorkCheck.pwcPreWorkCheckStepCd === 'PWC000001'
          && this.preWorkCheck.apprCycle === 'M', // 결재버튼 활성화 기준
        param: this.preWorkCheck, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000017', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          sopPreWorkCheckId: this.preWorkCheck.sopPreWorkCheckId,
          isApprContent: true
        },
        approvalRequestName: this.preWorkCheck.checkName, // 결재요청명 (문서 title)
        approvalConnId: this.preWorkCheck.sopPreWorkCheckId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
    imprTabInfo() {
      return {
        key: this.popupParam.sopPreWorkCheckId, // 
        ibmTaskTypeCd: 'ITT0000195',
        ibmTaskUnderTypeCd: 'ITTU000220',
        requestContents: `(${this.preWorkCheck.yearmonth}) ${this.preWorkCheck.deptName} ${this.preWorkCheck.checkName}`,
        disabled: this.disabled,
        isOld: this.isOld,
        gridItem: {
          listUrl: '',
          param: null,
          title: '일상점검결과 별 개선사항 목록',
          data: this.preWorkCheck.imprs,
          merge: [
            { index: 0, colName: 'sopPreWorkCheckClassCd' },
            { index: 1, colName: 'checkItemName' },
          ],
          columns: [
            {
              name: 'sopPreWorkCheckClassName',
              field: 'sopPreWorkCheckClassName',
              label: '점검구분',
              align: 'center',
              sortable: true,
              style: 'width:120px',
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목명',
              align: 'left',
              sortable: true,
              style: 'width:150px',
            },
            {
              name: 'checkItemSubName',
              field: 'checkItemSubName',
              label: '점검세부항목명',
              align: 'left',
              sortable: true,
              style: 'width:300px',
            },
            {
              name: 'checkDate',
              field: 'checkDate',
              label: '점검일',
              align: 'center',
              sortable: true,
              style: 'width:90px',
            },
          ],
        },
        gridOther: {
          title: 'LBL0010066',  // 항목외 개선 목록
        },
      }
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.pwc.check.get.url
      this.holidayUrl = selectConfig.sys.holiday.list.url;
      this.listItemUrl = selectConfig.sop.pwc.item.list.url
      this.saveUrl = transactionConfig.sop.pwc.check.update.url
      this.completeUrl = transactionConfig.sop.pwc.check.complete.url
      this.deleteUrl = transactionConfig.sop.pwc.check.delete.url
      this.approvalUrl = transactionConfig.sop.pwc.check.update.url
      // code setting
      // list setting
      this.getData();
    },
    getData() {
      let promises = [
        {
          func: this.getCheckItems,
        },
        {
          func: this.getHolidaies,
        },
        {
          func: this.search,
        },
      ];
      this.$comm.orderedPromise(promises);
    },
    search() {
      return new Promise(resolve => {
        this.getDetail();
        resolve(true);
      });
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopPreWorkCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.preWorkCheck, _result.data);
          this.$set(this.preWorkCheck, 'saveDeptCd', this.preWorkCheck.deptCd)

          this.changePlantDept();
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // nothing
      }
    },
    getCheckItems() {
      return new Promise(resolve => {
        // 사업장, 점검부서별 점검항목 정보 조회
        this.$http.url = this.listItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          // plantCd: this.preWorkCheck.plantCd,
          // deptCd: this.preWorkCheck.deptCd,
          useFlag: 'Y'
        };
        this.$http.request((_result) => {
          this.checkOriginItems = this.$_.map(_result.data, item => {
            return this.$_.extend(item, {
              pwcResultCd: '', // 'PR00000001',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          })
          if (!this.isOld) this.changePlantDept();
          
          resolve(true);
        },);
      });
    },
    getHolidaies() {
      return new Promise(resolve => {
        this.$http.url = this.holidayUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          year: this.$comm.moment(this.preWorkCheck.yearmonth).format('YYYY')
        };
        this.$http.request((_result) => {
          this.holidaies = _result.data
          resolve(true);
        },);
      });
    },
    isHoliday(day) {
      // 일 : 0
      // 토 : 6
      let dayOfWeek = this.$comm.moment(day).day();
      if ([0, 6].indexOf(dayOfWeek) > -1) {
        return true;
      } else if (this.$_.findIndex(this.holidaies, { holiday: day }) > -1) {
        return true;
      } else {
        return false;
      }
    },
    resultReset() {
      this.$set(this.preWorkCheck, 'targets', []);
      this.$set(this.preWorkCheck, 'monthResults', []);
    },
    changePlantDept(flag, deptName) {
      /**
       * 사업장, 부서가 변경되었을 경우
       * 점검구분 표시
       */
      if (flag === 'D') this.$set(this.preWorkCheck, 'deptName', deptName);
      if (this.preWorkCheck.plantCd 
        && this.preWorkCheck.deptCd
        && this.checkOriginItems && this.checkOriginItems.length > 0) {
          let checkClassItems = this.$_.uniqWith(this.$_.map(this.$_.filter(this.checkOriginItems, { plantCd: this.preWorkCheck.plantCd, deptCd: this.preWorkCheck.deptCd }), item => {
            return {
              sopPreWorkCheckClassCd: item.sopPreWorkCheckClassCd,
              sopPreWorkCheckClassName: item.sopPreWorkCheckClassName,
            }
          }), this.$_.isEqual)
          if (checkClassItems && checkClassItems.length > 0
            && this.preWorkCheck.sopPreWorkCheckClassCd
            && this.$_.findIndex(checkClassItems, checkClassItem => {
              return this.preWorkCheck.sopPreWorkCheckClassCd === checkClassItem.sopPreWorkCheckClassCd
            }) === -1) {
            this.$set(this.$data, 'checkClassItems', [])
            this.$set(this.preWorkCheck , 'sopPreWorkCheckClassCd', null)
            this.resultReset();
          } else if (!checkClassItems || checkClassItems.length === 0) {
            this.$set(this.$data, 'checkClassItems', [])
            this.$set(this.preWorkCheck , 'sopPreWorkCheckClassCd', null)
            this.resultReset()
          } else {
            this.checkClassItems = checkClassItems;
            this.changeInfo();
          }
      } else {
        this.$set(this.$data, 'checkClassItems', [])
        this.$set(this.preWorkCheck , 'sopPreWorkCheckClassCd', null)
        this.resultReset();
      }
    },
    changeInfo() {
      /**
       * 사업장, 점검주관부서, 점검년월, 점검구분이 변경 되었을 떄 
       * 그에 맞게 대상과 결과 정보 setting
       */
      if (this.checkClassItems && this.checkClassItems.length > 0 
        && this.preWorkCheck.sopPreWorkCheckClassCd 
        && this.preWorkCheck.yearmonth) {
          this.checkItems = this.$_.cloneDeep(this.$_.filter(this.checkOriginItems, { 
            plantCd: this.preWorkCheck.plantCd, 
            deptCd: this.preWorkCheck.deptCd,
            sopPreWorkCheckClassCd: this.preWorkCheck.sopPreWorkCheckClassCd,
          }));
          this.setInfo();
      } else {
        // 대상과 결과 정보가 다시 셋팅 되는 경우는 신규등록인 경우 밖에 없음
        this.resultReset();
      }
    },
    setInfo() {
      return new Promise(resolve => {
        let yearmonth = this.preWorkCheck.yearmonth;
        let yearmonthDays = this.$comm.moment(yearmonth).daysInMonth();
        let targets = [];
        let childCols = [];
        let monthResults = this.$_.cloneDeep(this.checkItems);
        for (let idx = 1; idx <= yearmonthDays; idx++) {
          let date = `${yearmonth}-${this.$_.padStart(idx, 2, '0')}`
          let isHoliday = this.isHoliday(date);
          childCols.push({
            name: `result${this.$_.padStart(idx, 2, '0')}`,
            field: `result${this.$_.padStart(idx, 2, '0')}`,
            label: `${this.$_.padStart(idx, 2, '0')}`,
            align: 'center',
            style: 'width:30px',
            sortable: false,
            headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
            tdColor: isHoliday ? '#D9D9D9' : null,
            setHeader: true,
            headerType: 'link',
          })
          if (!this.isOld) {
            targets.push({
              sopPreWorkCheckTargetId: '',  // 작업 전 안전점검 대상 일련번호
              sopPreWorkCheckId: '',  // 작업 전 안전점검 일련번호
              deptCd: this.preWorkCheck.deptCd,  // 점검부서
              userId: '',  // 점검자
              yearmonth: yearmonth,  // 년월
              checkDate: date,  // 점검일
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
              results: this.checkItems,
            })
            this.$_.forEach(monthResults, monthResult => {
              this.$set(monthResult, `${date}`, '')
            })
          }
        }
        if (!this.isOld) {
          this.$set(this.preWorkCheck, 'checkName', `${this.preWorkCheck.yearmonth} ${this.preWorkCheck.deptName} 일상점검`);
          this.$set(this.preWorkCheck, 'targets', targets);
          this.$set(this.preWorkCheck, 'monthResults', this.$_.map(monthResults, item => {
            return this.$_.extend(item, {
              addInfo: '',
            })
          }));
        }
        this.grid.columns = this.$_.concat(this.grid.defaultColumns, childCols);

        resolve(true);
      });
    },
    /* eslint-disable no-unused-vars */
    savePreWorkCheck() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.pwc.check.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.pwc.check.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preWorkCheck.regUserId = this.$store.getters.user.userId
              this.preWorkCheck.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    savePreWorkCheckCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopPreWorkCheckId = result.data
      this.getDetail();
    },
    completePreWorkCheck() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preWorkCheck.chgUserId = this.$store.getters.user.userId
              
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completePreWorkCheckCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    removePreWorkCheck() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopPreWorkCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalPreWorkCheck() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preWorkCheck.chgUserId = this.$store.getters.user.userId
              
              this.isApproval = !this.isApproval
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    // [S] 결재관련 
    approvalPreWorkCheckCallback(result) { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    // [E]] 결재관련 
  }
};
</script>